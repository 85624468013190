// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300i,400i,500i,600i,700i,300,400,500,600,700|Poppins:400,500&display=swap');

// Body
$body-bg: #f3f3f3;

// Typography
$font-family-sans-serif: 'Lato', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$headings-font-family: 'Poppins';

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Bootstrap colors
$theme-colors: (
    "primary": #0058b7,
    "secondary": #5f676b,
    "success": #4caf50,
    "info": #1faed1,
    "warning": #e58800,
    "danger": #c62f3e,
    "dark": #1d1d1b,
    "light": #f1f6f9 //ANGEL WHITE,
);